require('./bootstrap');

window.Vue = require('vue').default;
import Datepicker from 'vuejs-datepicker';
import {en, nl} from 'vuejs-datepicker/dist/locale'

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('tijdslot', require('./components/Slot.vue').default);

Vue.component('my-component', {
    components: {
        Datepicker
    }
});


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */



const OfferteRegel = {
    id: Math.floor(Math.random() * (99999999 - 9999 + 1)) + 9999,
    template: '<div>NRG Internetdiensten</div>'
   }

const app = new Vue({
    el: '#app',
    data: {
        slots: [],
        datum: '',
    },
    methods: {
        addFactuurRegel () {
            this.factuurregels.push(OfferteRegel)
        },
        addOfferteRegel () {
            this.regels.push(OfferteRegel)
        },
        removeOfferteRegel (id) {
            //this.regels.push(OfferteRegel)
            this.regels.splice(this.regels.indexOf(id), 1);
        }
      },
      components: {
        Datepicker: Datepicker
    },
      watch: {
        datum: function(val) {

            var date = new Date(val).toISOString().slice(0,10);

            this.datum = date;

            axios.get('/slots/'+date)
            .then(
                response => this.slots = response.data
            );

        }
    }
});
