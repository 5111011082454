<template>
    <div class="form-check">
        <input class="form-check-input" type="radio" name="van" id="van" :value="item" :monday-first="true">
        <label class="form-check-label" for="exampleRadios1">
        <slot></slot> @{{item}}
        </label>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        }
    }
    //test
</script>
